export const credentials = {
    broadcaster: "Ktls2021!!!*",
    broadcasterEvent: "petra",
    broadcasterDemo: "beplay2022",
    broadcasterEvent3: "luccasneto2022",
    broadcasterEvent4: "imm2023",
    broadcasterEvent5: "bachianas2023",
    broadcasterParelheiros: "petra",
    broadcasterPreview: "preview2022",
    broadcasterMBoiMirim: "80adecadadovaletudo2023",
    broadcasterCampoLimpo: "clarorio2023",
    broadcasterSantoAmaro: "ipiranga2022",
    broadcasterCidadeAdemar: "jabaquara2022",
    broadcasterButanta: "ccxp2023",
    broadcasterPinheiros: "opus",
    broadcastEvent10: "opus2023",
    broadcastEvent11: "opus2023",
    broadcastEvent12: "opus2023",
    broadcastEvent13: "etafestival2023",
    broadcastEvent14: "sesc",
    broadcastEvent15: "teatrobangu",
    broadcastEvent16: "brain2023",
    broadcastEvent17: "bachianas2023",
    broadcastEvent18: "clarosp2023",
    broadcastEvent19: "tbsp"
}