import React, {useEffect} from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {useLinkUser} from './context/lock'

import Home from "./pages/Home";

import Dev from "./pages/dev";
//Clients routes
import ClientArea from "./pages/client/client-area";
import ClientResources from "./pages/client/client-resources";
import ClientResources10 from "./pages/client/client-resources_event10";
import ClientResources11 from "./pages/client/client-resources_event11";
import ClientResources12 from "./pages/client/client-resources_event12";
import ClientResources13 from "./pages/client/client-resources_event13";
import ClientResources14 from "./pages/client/client-resources_event14";
import ClientResources15 from "./pages/client/client-resources_event15";
import ClientResources16 from "./pages/client/client-resources_event16";
import ClientResources17 from "./pages/client/client-resources_event17";
import ClientResources18 from "./pages/client/client-resources_event18";
import ClientResources19 from "./pages/client/client-resources_event19";
import ClientResourcesDemo from "./pages/client/client-resources-demo";
import ClientResourcesEvent4 from "./pages/client/client-resources_evento4";
import ClientResourcesEvent3 from "./pages/client/client-resources_evento3";
import ClientResourcesEvent5 from "./pages/client/client-resources_event5";
import ClientAd from "./pages/client/ad";
import ClientAdDemo from "./pages/client/ad-demo";
import ClientAdGupy from "./pages/client/ad-demo-gupy";
import ClientLibras from "./pages/client/libras";
import ClientLibrasDemo from "./pages/client/libras-demo";
import ClientLibrasContent from "./pages/client/libras-content";
import ClientLibrasDemoContent from './pages/client/libras-content-demo';
import ClientTranslate from "./pages/client/translate";
import ClientTranslateDemo from './pages/client/translate-demo';
import ClientAudioguia from "./pages/client/ad-audio-original";
import ClientCurso1 from "./pages/client/ad_curso1";
import ClientCurso2 from "./pages/client/ad_curso2";
import ClientLibras3 from "./pages/client/libras-content-gupy"

import ClientAdEvent4 from "./pages/client/ad_event4";
import ClientAdevent10 from "./pages/client/ad_event10";
import ClientAdevent11 from "./pages/client/ad_event11";
import ClientAdevent12 from "./pages/client/ad_event12";
import ClientAdevent13 from "./pages/client/ad_event13";
import ClientAdevent15 from "./pages/client/ad_event15";
import ClientAdevent16 from "./pages/client/ad_event16";
import ClientAdevent17 from "./pages/client/ad_event17";
import ClientAdevent18 from "./pages/client/ad_event18";
import ClientAdevent19 from "./pages/client/ad_event19";
import ClientTranslate02 from "./pages/client/translate02";
import ClientTranslate03 from "./pages/client/translate03";
import ClientTranslate04 from "./pages/client/translate04";

//eventos simultaneos
import ParelheirosResources from "./pages/client/parelheiros"
import ParelheirosAd from "./pages/client/ad-ermelino-matarazzo"
import ParelheirosHost from "./pages/host/setup-parelheiros"
import ParelheirosHostAd from "./pages/host/ad-parelheiros"

import CapelaSocorro from "./pages/client/capela-socorro"
import PreviewAd from "./pages/client/ad-capela-socorro"
import CapelaSocorroHost from "./pages/host/setup-capela-socorro"
import CapelaSocorroHostAd from "./pages/host/ad-capela-socorro"

import Mboimirim from "./pages/client/m-boi-mirim"
import MBoiMirimAd from "./pages/client/ad-m-boi-mirim"
import MBoiMirimHost from "./pages/host/setup-m-boi-mirim"
import MBoiMirimHostAd from "./pages/host/ad-m-boi-mirim"

import CampoLimpo from "./pages/client/campo-limpo"
import CampoLimpoAd from "./pages/client/ad-campo-limpo"
import CampoLimpoHost from "./pages/host/setup-campo-limpo"
import CampoLimpoHostAd from "./pages/host/ad-campo-limpo"

import SantoAmaro from "./pages/client/santo-amaro"
import SantoAmaroAd from "./pages/client/ad-santo-amaro"
import SantoAmaroHost from "./pages/host/setup-santo-amaro"
import SantoAmaroHostAd from "./pages/host/ad-santo-amaro"

import CidadeAdemar from "./pages/client/cidade-ademar"
import CidadeAdemarAd from "./pages/client/ad-cidade-ademar"
import CidadeAdemarHost from "./pages/host/setup-cidade-ademar"
import CidadeAdemarHostAd from "./pages/host/ad-cidade-ademar"

import Butanta from "./pages/client/butanta"
import ButantaAd from "./pages/client/ad-butanta"
import ButantaHost from "./pages/host/setup-butanta"
import ButantaHostAd from "./pages/host/ad-butanta"

import Pinheiros from "./pages/client/pinheiros"
import PinheirosAd from "./pages/client/ad-pinheiros"
import PinheirosHost from "./pages/host/setup-pinheiros"
import PinheirosHostAd from "./pages/host/ad-pinheiros"


//Host routes
import HostLogin from "./pages/host/login";
import HostChooseEvent from "./pages/host/choose-event";
import HostSetupResource from "./pages/host/setup-resource";
import HostSetupResourceDemo from "./pages/host/setup-resource-demo";
import HostAd from "./pages/host/ad";
import HostAdDemo from "./pages/host/ad-demo";
import HostLibras from './pages/host/libras';
import HostLibrasDemo from "./pages/host/libras-demo";
import HostAudioguia from "./pages/host/ad-audio-original";
import HostTranslate from "./pages/host/translate";
import HostTranslateDemo from "./pages/host/translate-demo";
import HostCurso1 from "./pages/host/ad_curso1";
import HostCurso2 from "./pages/host/ad_curso2";
import HostAdEvent4 from "./pages/host/ad_event4";
import HostAdEvent10 from "./pages/host/ad_event10";
import HostAdEvent11 from "./pages/host/ad_event11";
import HostAdEvent12 from "./pages/host/ad_event12";
import HostAdEvent13 from "./pages/host/ad_event13";
import HostAdEvent15 from "./pages/host/ad_event15";
import HostAdEvent16 from "./pages/host/ad_event16";
import HostAdEvent17 from "./pages/host/ad_event17";
import HostAdEvent18 from "./pages/host/ad_event18";
import HostAdEvent19 from "./pages/host/ad_event19";
import HostTranslate02 from "./pages/host/translate02";
import HostTranslate03 from "./pages/host/translate03";
import HostTranslate04 from "./pages/host/translate04";
// import do evento da gupy

import LibrasGupy from "./pages/client/libras-content-gupy";
import HostLibrasGupy from "./pages/host/libras-gupy";
import ChooseEvent4 from "./pages/host/setup-resource-event4";
import ChooseEvent3 from "./pages/host/setup-resource-event3";
import ChooseEvent5 from './pages/host/setup-resource-event5';
import HostEvent10 from "./pages/host/setup-resource_event10";
import HostEvent11 from "./pages/host/setup-resource_event11";
import HostEvent12 from "./pages/host/setup-resource_event12";
import HostEvent13 from "./pages/host/setup-resource_event13";
import HostEvent14 from "./pages/host/setup-resource_event14";
import HostEvent15 from "./pages/host/setup-resource_event15";
import HostEvent16 from "./pages/host/setup-resource_event16";
import HostEvent17 from "./pages/host/setup-resource_event17";
import HostEvent18 from "./pages/host/setup-resource_event18";
import HostEvent19 from "./pages/host/setup-resource_event19";

//NotFound
import notFound from "./pages/NotFound";
import ChooseEvent from "./pages/host/choose-event";

import ClaroPage from './pages/claro'



const Routes = () => {

  const {lock, setLock} = useLinkUser()
  

  const handleDate = () => {
    const date =  Date.now()
    const gameDate = new Date('Sun Aug 20 2023 06:30:00 GMT-0300 (Brasilia Standard Time)')
    return date >= gameDate.getTime()
}


useEffect(() => {
  const myInterval =  setInterval(() => {

     if(handleDate()){
        setLock(false)
        clearInterval(myInterval)

     }
    }, 1000)
}, [setLock])

// useEffect(() => {

//   if(window.location.pathanme === '/tbsp-ad'){
//     window.location.href = 'https://uol.com.br'
//   }
//   console.log(window.location.pathname)
// },[])
 

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/dev" exact component={Dev}></Route>

        {/* Evento 10 */}
        <Route path="/homemcomh" exact component={ClientResources10}  />
        <Route path="/homemcomh/ad" exact component={ClientAdevent10}  />

        <Route path="/organizador/eventos/event10/escolher-recurso" exact component={HostEvent10} />
        <Route path="/organizador/eventos/transmitir/event10/ad" exact component={HostAdEvent10} />

         {/* Fim do Evento 10 */}

                 {/* Evento 11 */}
        <Route path="/ssvemai" exact component={ClientResources11}  />
        <Route path="/ssvemai/ad" exact component={ClientAdevent11}  />

        <Route path="/organizador/eventos/event11/escolher-recurso" exact component={HostEvent11} />
        <Route path="/organizador/eventos/transmitir/event11/ad" exact component={HostAdEvent11} />

         {/* Fim do Evento 11 */}

                 {/* Evento 12 */}
        <Route path="/ovisonvoador" exact component={ClientResources12}  />
        <Route path="/ovisonvoador/ad" exact component={ClientAdevent12}  />

        <Route path="/organizador/eventos/event12/escolher-recurso" exact component={HostEvent12} />
        <Route path="/organizador/eventos/transmitir/event12/ad" exact component={HostAdEvent12} />

         {/* Fim do Evento 12 */}

                          {/* Evento 13 */}
        <Route path="/sescsantana/recursos" exact component={ClientResources13}  />
        <Route path="/etafestival" exact component={ClientAdevent13}  />

        <Route path="/organizador/eventos/event13/escolher-recurso" exact component={HostEvent13} />
        <Route path="/organizador/eventos/transmitir/event13/ad" exact component={HostAdEvent13} />

         {/* Fim do Evento 13 */}


                 {/* Evento 14 */}
        <Route path="/sesc-guarulhos" exact component={ClientResources14}  />
        <Route path="/sesc-guarulhos/ad" exact component={ClientTranslate02}  />
        <Route path="/atmedios/espanhol" exact component={ClientTranslate04} />

        <Route path="/organizador/eventos/event14/escolher-recurso" exact component={HostEvent14} />
        <Route path="/organizador/eventos/transmitir/event14/ad" exact component={HostTranslate02} />
        <Route path="/organizador/eventos/transmitir/event14/espanhol" exact component={HostTranslate04} />

         {/* Fim do Evento 14 */}

                          {/* Evento 15 */}
        <Route path="/teatrobangu" exact component={ClientResources15}  />
        <Route path="/teatrobangu/ad" exact component={ClientAdevent15}  />
        <Route path="/tbsp/english" exact component={ClientTranslate03}  />

        <Route path="/organizador/eventos/event15/escolher-recurso" exact component={HostEvent15} />
        <Route path="/organizador/eventos/transmitir/event15/ad" exact component={HostAdEvent15} />
        <Route path="/organizador/eventos/transmitir/event15/ingles" exact component={HostTranslate03} />

         {/* Fim do Evento 15 */}

                                   {/* Evento 16 */}
        <Route path="/brain/recursos" exact component={ClientResources16}  />
        <Route path="/brain/ad" exact component={ClientAdevent16}  />
    

        <Route path="/organizador/eventos/event16/escolher-recurso" exact component={HostEvent16} />
        <Route path="/organizador/eventos/transmitir/event16/ad" exact component={HostAdEvent16} />


         {/* Fim do Evento 16 */}

        {/* Evento 17 */}
        <Route path="/bachianas-encerramento/recursos" exact component={ClientResources17}  />
        <Route path="/bachianas-encerramento/ad" exact component={ClientAdevent17}  />
    

        <Route path="/organizador/eventos/event17/escolher-recurso" exact component={HostEvent17} />
        <Route path="/organizador/eventos/transmitir/event17/ad" exact component={HostAdEvent17} />


         {/* Fim do Evento 17 */}


                {/* Evento 18 */}
        <Route path="/teatroclarosp/recursos" exact component={ClientResources18}  />
        <Route path="/teatroclarosp/ad" exact component={ClientAdevent18}  />

        <Route path="/organizador/eventos/event18/escolher-recurso" exact component={HostEvent18} />
        <Route path="/organizador/eventos/transmitir/event18/ad" exact component={HostAdEvent18} />

         {/* Fim do Evento 18 */}


          {/* Evento 19 */}
        <Route path="/tbsp" exact component={ClientResources19}  />
        <Route path="/tbsp-ad" exact component={ClientAdevent19}  />
        <Route path="/tbsp/english" exact component={ClientTranslate}  />

        <Route path="/organizador/eventos/event19/escolher-recurso" exact component={HostEvent19} />
        <Route path="/organizador/eventos/transmitir/event19/ad" exact component={HostAdEvent19} />
        <Route path="/organizador/eventos/transmitir/event19/ingles" exact component={CidadeAdemarHostAd} />

         {/* Fim do Evento 19 */}

{/* Evento de preview */}

                <Route path="/preview/audio" exact component={PreviewAd} />
                <Route path='/preview/libras' exact component={ClientLibras3} />
                <Route path="/organizador/eventos/preview/escolher-recurso" exact component={CapelaSocorroHost} />
        <Route path="/organizador/eventos/transmitir/preview/audio" exact component={CapelaSocorroHostAd} />
        <Route path="/organizador/eventos/transmitir/preview/libras" exact component={HostLibrasGupy} />

{/* Fim Evento de preview */}
      {/* EVENTOS SIMULTANEOS */}

      {/* ermelino-matarazzo */}
        <Route path="/matilda/recursos" exact component={ParelheirosResources} />
        <Route path="/matilda/ad" exact component={ParelheirosAd} />
        

        <Route path="/organizador/eventos/matilda/escolher-recurso" exact component={ParelheirosHost} />
        <Route path="/organizador/eventos/transmitir/matilda/ad" exact component={ParelheirosHostAd} />

        
      {/* FIM ermelino-matarazzo */}

      {/* São Miguel Paulista - CEU Parque São Carlos */}
        <Route path="/vilaprudente/recursos" exact component={CapelaSocorro} />
        {/* <Route path="/audio/vilaprudente" exact component={CapelaSocorroAd} /> */}




      {/* FIM São Miguel Paulista - CEU Parque São Carlos*/}

      {/* Itaim Paulista - CEU Vila Curuçá */}
            
            <Route path="/audio/aricanduva" exact component={MBoiMirimAd} />

            <Route path="/organizador/eventos/aricanduva/escolher-recurso" exact component={MBoiMirimHost} />
            <Route path="/organizador/eventos/transmitir/audio/aricanduva" exact component={MBoiMirimHostAd} />
      {/* FIm Itaim Paulista - CEU Vila Curuçá  */}

            {/* Guaianases - CEU Jambeiro */}
            <Route path="/clarorio/recursos" exact component={CampoLimpo} />
            <Route path="/clarorio/ad" exact component={CampoLimpoAd} />

            <Route path="/organizador/eventos/clarorio/escolher-recurso" exact component={CampoLimpoHost} />
            <Route path="/organizador/eventos/transmitir/clarorio/ad" exact component={CampoLimpoHostAd} />
      {/* FIM Guaianases - CEU Jambeiro */}

            {/* Cidade Tiradentes - CEU Inácio Monteiro*/}
            <Route path="/ipiranga/recursos" exact component={SantoAmaro} />
            <Route path="/audio/ipiranga" exact component={SantoAmaroAd} />

            <Route path="/organizador/eventos/ipiranga/escolher-recurso" exact component={SantoAmaroHost} />
            <Route path="/organizador/eventos/transmitir/audio/ipiranga" exact component={SantoAmaroHostAd} />
      {/* FIM DA Cidade Tiradentes - CEU Inácio Monteiro  */}

            {/* Itaquera - CEU Aricanduva*/}
            <Route path="/jabaquara/recursos" exact component={CidadeAdemar} />
            

            <Route path="/organizador/eventos/jabaquara/escolher-recurso" exact component={CidadeAdemarHost} />
            
      {/* FIM DA Itaquera - CEU Aricanduva */}

            {/* São Mateus - CEU São Mateus*/}
            
            
      {/* FIM DA São Mateus - CEU São Mateus  */}

            {/* Penha - CEU Quinta Do Sol*/}
            <Route path="/onovoabelaeafera/recursos" exact component={Pinheiros} />
            <Route path="/onovoabelaeafera/ad" exact component={PinheirosAd} />

            <Route path="/organizador/eventos/onovoabelaeafera/escolher-recurso" exact component={PinheirosHost} />
            <Route path="/organizador/eventos/transmitir/onovoabelaeafera/ad" exact component={PinheirosHostAd} />
      {/* FIM DA Penha - CEU Quinta Do Sol */}

        <Route path="/" exact component={Home} />
        {/*Client Routes */}

        {/* Primeiro evento CLient - Evento principal que foi alterado para Petra */}
        <Route path="/petra/recursos" exact component={ClientResources} />
        <Route
          path="/petra/ad"
          exact
          component={ClientAd}
        />
        <Route
          path="/petra/libras"
          exact
          component={ClientLibrasContent}
        />

            <Route path="/netflixnaccxp/recursos" exact component={Butanta} />
            

            <Route path="/organizador/eventos/netflixnaccxp/escolher-recurso" exact component={ButantaHost} />

        <Route path="/G3ICT/portugues" exact component={ClientTranslate} />
        <Route path="/netflixnaccxp/ad" exact component={ButantaAd} />
        <Route path="/G3ICT/english" exact component={CidadeAdemarAd} />

        {/* Host */}
        <Route
            path="/organizador/eventos/transmitir/G3ICT/portugues"
            exact
            component={HostTranslate}
          />
          <Route path="/organizador/eventos/transmitir/netflixnaccxp/ad" exact component={ButantaHostAd} />
          <Route path="/organizador/eventos/transmitir/G3ICT/english" exact component={CidadeAdemarHostAd} />

  
        <Route
          path="/organizador/eventos/escolher-recurso"
          exact
          component={HostSetupResource}
        />


        <Route
          path="/organizador/eventos/transmitir/ad"
          exact
          component={HostAd}
        />

        <Route
          path="/organizador/eventos/transmitir/libras"
          exact
          component={HostLibras}
        />






        {/* Fim primeiro evento client - Evento principal */}

        {/* 
        *
        */}

        {/* Inicio segundo evento client - Evento 3 */}
        <Route path="/80adecadadovaletudo/recursos" exact component={ClientResourcesEvent3} />
        <Route path="/80adecadadovaletudo/ad" exact component={ClientAudioguia} />
        <Route
          path="/organizador/eventos/transmitir/80adecadadovaletudo/ad"
          exact
          component={HostAudioguia}
        />

        <Route path="/organizador/eventos/80adecadadovaletudo/escolher-recurso" exact component={ChooseEvent3} />

        {/* Fim segundo evento client - Evento 3 */}

        {/* 
        *
        */}

        {/* Inicio terceiro evento client - Evento 4 */}

        <Route path="/umalindamulheromusical/recursos" exact component={ClientResourcesEvent4} />
        <Route path="/umalindamulheromusical/ad" exact component={ClientAdEvent4} />
        <Route
          path="/umalindamulheromusical/libras"
          exact
          component={ClientLibrasContent}
        />

        <Route path="/organizador/eventos/umalindamulheromusical/escolher-recurso" exact component={ChooseEvent4} />
        <Route
          path="/organizador/eventos/transmitir/umalindamulheromusical/ad"
          exact
          component={HostAdEvent4}
        />

        <Route
          path="/organizador/eventos/transmitir/umalindamulheromusical/libras"
          exact
          component={HostLibras}
        />
   
        

        {/* Fim terceiro evento client - Evento 4 */}

        {/* Inicio quarto evento */}
       

        

       <Route path="/organizador/eventos/petra/escolher-recurso" exact component={ParelheirosHost} />
        <Route
          path="/organizador/eventos/transmitir/sedpcd/ad"
          exact
          component={HostCurso1} 
        />
        <Route path="/sedpcd/ad" exact component={MBoiMirimAd} /> 

        <Route
          path="/organizador/eventos/transmitir/libras"
          exact
          component={HostLibras}
        />

      <Route
          path="/organizador/eventos/transmitir/claro/audioguia_ad"
          exact
          component={MBoiMirimHostAd}
        />
        
        {/* Fim do quarto evento */}


        {/* Quinto evento */}

        <Route path="/organizador/eventos/bachianas/escolher-recurso" exact component={ChooseEvent5} />

        <Route
          path="/bachianas/recursos"
          exact
          component={ClientResourcesEvent5}
        />

        <Route
          path="/bachianas/ad"
          exact
          component={ClientCurso2}
        />

      <Route path="/expocine/piso" exact component={MBoiMirimAd} /> 

      <Route path="/expocine/english" exact component={ClientAudioguia} />

      <Route path='/expocine/espanol' exact component={PinheirosAd} />
        <Route
          path="/organizador/eventos/transmitir/expocine/english"
          exact
          component={HostAudioguia}
        />

<Route
          path="/organizador/eventos/transmitir/expocine/espanol"
          exact
          component={PinheirosHostAd}
        />


      <Route
          path="/organizador/eventos/transmitir/bachianas/ad"
          exact
          component={HostCurso2}
        />

        <Route
          path="/organizador/eventos/transmitir/expocine/piso"
          exact
          component={MBoiMirimHostAd}
        />

        {/* Fim Quinto evento */}

        {/* Evento de demonstração */}
        <Route path="/beplay/recursos" exact component={ClientResourcesDemo} />
        <Route path="/evento/ad" exact component={ClientAdDemo} />
        <Route
          path="/evento/libras"
          exact
          component={ClientLibrasDemoContent}
        />
        <Route path="/evento/traducao" exact component={ClientTranslateDemo} />


<Route
          path="/organizador/eventos/demo/escolher-recurso"
          exact
          component={HostSetupResourceDemo}
        />


<Route
          path="/organizador/eventos/transmitir/demo/ad"
          exact
          component={HostAdDemo}
        />

        <Route
          path="/organizador/eventos/transmitir/demo/libras"
          exact
          component={HostLibrasDemo}
        />

        <Route
          path="/organizador/eventos/transmitir/demo/traducao"
          exact
          component={HostTranslateDemo} />

        {/* Fim do Evento de demonstração */}



        <Route path="/eventos" exact component={ClientArea} />
              
 
        {/*Host Routes */}
        <Route path="/organizador/login" exact component={HostLogin} />
        <Route
          path="/organizador/eventos-disponiveis"
          exact
          component={HostChooseEvent}
        />
  
        {/* Expocine traducao Ingles para portugues */}
 


        {/* Expocine traducao espanhol para portugues */}
      
      
        {/*NoFound Route */}
        {/* <Route
          path="/Claro"
          exact
          component={ClaroPage}
        /> */}
     {/* <Route path="/claro/ad" exact component={ClientCurso1} /> */}
        {/* <Route path="/claro/libras" exact component={ClientLibrasContent} /> */}

      {/* {!lock ? 
           <>
        <Route path="/claro/ad" exact component={ClientCurso1} />
        <Route path="/claro/libras" exact component={ClientLibrasContent} />
       
       </>

      : 
      <>
       <Route path="/claro/ad" exact component={ClientArea} />
       <Route path="/claro/libras" exact component={ClientArea} />
      </>

      } */}

      {/* {lock && (
        <>
          <Route path="/claro/ad" exact component={ClientCurso1} />
          <Route path="/claro/libras" exact component={ClientLibrasContent} />
          </>
      )} */}
      
       
        <Route path="*" component={notFound} />

       
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
