import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { credentials } from "../../../auth/credentials";
import Menu from "../../../layout/Menu";
import { Container } from "./styles";
import { SystemButton } from "../../../components/buttons/styles";
import Footer from "../../../layout/Footer";
import arrowBack from "../../../assets/icon-arrow-left.svg";
import iconAlert from "../../../assets/icon-alert.svg";

const ChooseEvent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const key = localStorage.getItem("key");
  if (key !== credentials.broadcaster) {
    history.push("/organizador/login");
  }

  const [tokenEvent10, setTokenEvent10] = useState("")
  const [tokenEvent11, setTokenEvent11] = useState("")
  const [tokenEvent12, setTokenEvent12] = useState("")
  const [tokenEvent13, setTokenEvent13] = useState("")
  const [tokenEvent14, setTokenEvent14] = useState("")
  const [tokenEvent15, setTokenEvent15] = useState("")
  const [tokenEvent16, setTokenEvent16] = useState("")
  const [tokenEvent17, setTokenEvent17] = useState("")
  const [tokenEvent18, setTokenEvent18] = useState("")
  const [tokenEvent19, setTokenEvent19] = useState("")

  //eventos simultaneos

  const [tokenParelheiros, setTokenParelheiros] = useState("")
  const [tokenCapelaSocorro, setTokenCapelaSocorro] = useState("")
  const [tokenMBoiMirim, setTokenMBoiMirim] = useState("")
  const [tokenCampoLimpo, setTokenCampoLimpo] = useState("")
  const [tokenSantoAmaro, setTokenSantoAmaro] = useState("")
  const [tokenCidadeAdemar, setTokenCidadeAdemar] = useState("")
  const [tokenButanta, setTokenButanta] = useState("")
  const [tokenPinheiros, setTokenPinheiros] = useState("")

  const [token, setToken] = useState("");
  const [tokenDemo, setTokenDemo] = useState("");
  const [tokenGupy, setTokenGupy] = useState("");
  const [tokenYoutube, setTokenYoutube] = useState("");
  const [storage, setStorage] = useState(
    localStorage.getItem("eventKey") || ""
  );


  

  //eventos simultaneo
  const parelheirosCode = credentials.broadcasterParelheiros;
  const capelaSocorroCode = credentials.broadcasterPreview;
  const mBoiMirimCode = credentials.broadcasterMBoiMirim;
  const campoLimpoCode = credentials.broadcasterCampoLimpo;
  const santoAmaroCode = credentials.broadcasterSantoAmaro;
  const cidadeAdemarCode = credentials.broadcasterCidadeAdemar;
  const butantaCode = credentials.broadcasterButanta;
  const pinheirosCode = credentials.broadcasterPinheiros;

  const eventCode = credentials.broadcasterEvent;
  const eventCodeDemo = credentials.broadcasterDemo;
  const eventSantoAmaro = credentials.broadcasterEvent4;
  const eventYoutubeCode = credentials.broadcasterEvent5;
  const event10Code = credentials.broadcastEvent10;
  const event11Code = credentials.broadcastEvent11;
  const event12Code = credentials.broadcastEvent12;
  const event13Code = credentials.broadcastEvent13;
  const event14Code = credentials.broadcastEvent14;
  const event15Code = credentials.broadcastEvent15;
  const event16Code = credentials.broadcastEvent16;
  const event17Code = credentials.broadcastEvent17;
  const event18Code = credentials.broadcastEvent18;
  const event19Code = credentials.broadcastEvent19;


  const handleChooseEvent10 = (e) => {
    e.preventDefault();

    if (tokenEvent10 !== event10Code) {
      return window.alert("Senha inválida")
    } else {
      setStorage(tokenEvent10);
      localStorage.setItem("eventKey", tokenEvent10);
      history.push("/organizador/eventos/event10/escolher-recurso");
    }
  };

  const handleChooseEvent11 = (e) => {
    e.preventDefault();

    if (tokenEvent11 !== event11Code) {
      return window.alert("Senha inválida")
    } else {
      setStorage(event11Code);
      localStorage.setItem("eventKey", event11Code);
      history.push("/organizador/eventos/event11/escolher-recurso");
    }
  };

  const handleChooseEvent12 = (e) => {
    e.preventDefault();

    if (tokenEvent12 !== event12Code) {
      return window.alert("Senha inválida")
    } else {
      setStorage(event12Code);
      localStorage.setItem("eventKey", event12Code);
      history.push("/organizador/eventos/event12/escolher-recurso");
    }
  };

  const handleChooseEvent13 = (e) => {
    e.preventDefault();

    if (tokenEvent13 !== event13Code) {
      return window.alert("Senha inválida")
    } else {
      setStorage(event13Code);
      localStorage.setItem("eventKey", event13Code);
      history.push("/organizador/eventos/event13/escolher-recurso");
    }
  };

  const handleChooseEvent14 = (e) => {
    e.preventDefault();

    if (tokenEvent14 !== event14Code) {
      return window.alert("Senha inválida")
    } else {
      setStorage(event14Code);
      localStorage.setItem("eventKey", event14Code);
      history.push("/organizador/eventos/event14/escolher-recurso");
    }
  };

  const handleChooseEvent15 = (e) => {
    e.preventDefault();

    if (tokenEvent15 !== event15Code) {
      return window.alert("Senha inválida")
    } else {
      setStorage(event15Code);
      localStorage.setItem("eventKey", event15Code);
      history.push("/organizador/eventos/event15/escolher-recurso");
    }
  };

  const handleChooseEvent16 = (e) => {
    e.preventDefault();

    if (tokenEvent16 !== event16Code) {
      return window.alert("Senha inválida")
    } else {
      setStorage(event16Code);
      localStorage.setItem("eventKey", event16Code);
      history.push("/organizador/eventos/event16/escolher-recurso");
    }
  };

  const handleChooseEvent17 = (e) => {
    e.preventDefault();

    if (tokenEvent17 !== event17Code) {
      return window.alert("Senha inválida")
    } else {
      setStorage(event17Code);
      localStorage.setItem("eventKey", event17Code);
      history.push("/organizador/eventos/event17/escolher-recurso");
    }
  };

  const handleChooseEvent18 = (e) => {
    e.preventDefault();

    if (tokenEvent18 !== event18Code) {
      return window.alert("Senha inválida")
    } else {
      setStorage(tokenEvent18);
      localStorage.setItem("eventKey", tokenEvent18);
      history.push("/organizador/eventos/event18/escolher-recurso");
    }
  };

  const handleChooseEvent19 = (e) => {
    e.preventDefault();

    if (tokenEvent19 !== event19Code) {
      return window.alert("Senha inválida")
    } else {
      setStorage(tokenEvent19);
      localStorage.setItem("eventKey", tokenEvent19);
      history.push("/organizador/eventos/event19/escolher-recurso");
    }
  };

  //Eventos simultaneos
  const handleChooseParelheiros = (e) => {
    e.preventDefault();

    if (tokenParelheiros !== parelheirosCode) {
      return window.alert("Senha inválida")
    } else {
      setStorage(tokenParelheiros);
      localStorage.setItem("eventKey", tokenParelheiros);
      history.push("/organizador/eventos/petra/escolher-recurso");
    }
  };

  const handleChooseCapelaSocorro = (e) => {
    e.preventDefault();

    if (tokenCapelaSocorro !== capelaSocorroCode) {
      return window.alert("Senha inválida")
    } else {
      setStorage(tokenCapelaSocorro);
      localStorage.setItem("eventKey", tokenCapelaSocorro);
      history.push("/organizador/eventos/preview/escolher-recurso");
    }
  };

  const handleChooseMBoiMirim = (e) => {
    e.preventDefault();

    if (tokenMBoiMirim !== mBoiMirimCode) {
      return window.alert("Senha inválida")
    } else {
      setStorage(tokenMBoiMirim);
      localStorage.setItem("eventKey", tokenMBoiMirim);
      history.push("/organizador/eventos/80adecadadovaletudo/escolher-recurso");
    }
  };

  const handleChooseCampoLimpo = (e) => {
    e.preventDefault();

    if (tokenCampoLimpo !== campoLimpoCode) {
      return window.alert("Senha inválida")
    } else {
      setStorage(tokenCampoLimpo);
      localStorage.setItem("eventKey", tokenCampoLimpo);
      history.push("/organizador/eventos/clarorio/escolher-recurso");
    }
  };

  const handleChooseSantoAmaro = (e) => {
    e.preventDefault();

    if (tokenSantoAmaro !== santoAmaroCode) {
      return window.alert("Senha inválida")
    } else {
      setStorage(tokenSantoAmaro);
      localStorage.setItem("eventKey", tokenSantoAmaro);
      history.push("/organizador/eventos/ipiranga/escolher-recurso");
    }
  };

  const handleChooseCidadeAdemar = (e) => {
    e.preventDefault();

    if (tokenCidadeAdemar !== cidadeAdemarCode) {
      return window.alert("Senha inválida")
    } else {
      setStorage(tokenCidadeAdemar);
      localStorage.setItem("eventKey", tokenCidadeAdemar);
      history.push("/organizador/eventos/jabaquara/escolher-recurso");
    }
  };

  const handleChooseButanta = (e) => {
    e.preventDefault();

    if (tokenButanta !== butantaCode) {
      return window.alert("Senha inválida")
    } else {
      setStorage(tokenButanta);
      localStorage.setItem("eventKey", tokenButanta);
      history.push("/organizador/eventos/netflixnaccxp/escolher-recurso");
    }
  };

  const handleChoosePinheiros = (e) => {
    e.preventDefault();

    if (tokenPinheiros !== pinheirosCode) {
      return window.alert("Senha inválida")
    } else {
      setStorage(tokenPinheiros);
      localStorage.setItem("eventKey", tokenPinheiros);
      history.push("/organizador/eventos/onovoabelaeafera/escolher-recurso");
    }
  };
  // FIm dos eventos simultaneos

  const handleChooseEvent = (e) => {
    e.preventDefault();

    if (token !== eventCode) {
      return window.alert("Senha inválida")
    } else {
      setStorage(token);
      localStorage.setItem("eventKey", token);
      history.push("/organizador/eventos/escolher-recurso");
    }
  };

  const handleChooseEventDemo = (e) => {
    e.preventDefault();

    if (tokenDemo !== eventCodeDemo) {
      return window.alert("Senha inválida")
    } else {
      setStorage(tokenDemo);
      localStorage.setItem("eventKey", tokenDemo);
      history.push("/organizador/eventos/demo/escolher-recurso");
    }
  };

  const handleChooseGupy = (e) => {
    e.preventDefault();

    if (tokenGupy !== eventSantoAmaro) {
      return window.alert("Senha inválida")
    } else {
      setStorage(tokenGupy);
      localStorage.setItem("eventKey", tokenGupy);
      history.push("/organizador/eventos/umalindamulheromusical/escolher-recurso");
    }
  };

  const handleChooseYoutube = (e) => {
    e.preventDefault();

    if (tokenYoutube !== eventYoutubeCode) {
      return window.alert("Senha inválida")
    } else {
      setStorage(tokenYoutube);
      localStorage.setItem("eventKey", tokenYoutube);
      history.push("/organizador/eventos/bachianas/escolher-recurso");
    }
  };

  return (
    <Container>
      <Menu />
      <div className="main">
        <div className="heading">
          <h2>{t("host-choose-event-title")}</h2>
          <p>{t("host-choose-event-subtitle")}</p>
        </div>

        {/* <form className="choose-event" onSubmit={handleChooseEvent19}>

<h3 className="h3">TEATRO BRADESCO</h3>
<label className="label" htmlFor="passwd">
  {t("host-choose-event-label")}
</label>
<div className="input-group">
  <input
    type="password"
    id="passwd"
    placeholder={t("host-choose-event-code-placeholder")}
    onChange={(e) => {
      setTokenEvent19(e.target.value);
    }}
  />
  <SystemButton>{t("host-choose-event-btn-confirm")}</SystemButton>
</div>
</form> */}

        {/* <form className="choose-event" onSubmit={handleChooseEvent17}>

          <h3 className="h3">FUNDAÇÃO BACHIANAS - ENCERRAMENTOS</h3>
          <label className="label" htmlFor="passwd">
            {t("host-choose-event-label")}
          </label>
          <div className="input-group">
            <input
              type="password"
              id="passwd"
              placeholder={t("host-choose-event-code-placeholder")}
              onChange={(e) => {
                setTokenEvent17(e.target.value);
              }}
            />
            <SystemButton>{t("host-choose-event-btn-confirm")}</SystemButton>
          </div>
        </form> */}

        
        {/* <form className="choose-event" onSubmit={handleChooseEvent16}>

            <h3 className="h3">BRAIN - MC DONALD'S</h3>
            <label className="label" htmlFor="passwd">
              {t("host-choose-event-label")}
            </label>
            <div className="input-group">
              <input
                type="password"
                id="passwd"
                placeholder={t("host-choose-event-code-placeholder")}
                onChange={(e) => {
                  setTokenEvent16(e.target.value);
                }}
              />
              <SystemButton>{t("host-choose-event-btn-confirm")}</SystemButton>
            </div>
</form> */}

        {/* <form className="choose-event" onSubmit={handleChooseEvent15}>

            <h3 className="h3">Teatro Bangu Shopping</h3>
            <label className="label" htmlFor="passwd">
              {t("host-choose-event-label")}
            </label>
            <div className="input-group">
              <input
                type="password"
                id="passwd"
                placeholder={t("host-choose-event-code-placeholder")}
                onChange={(e) => {
                  setTokenEvent15(e.target.value);
                }}
              />
              <SystemButton>{t("host-choose-event-btn-confirm")}</SystemButton>
            </div>
</form> */}
        

        {/* <form className="choose-event" onSubmit={handleChooseEvent13}>

            <h3 className="h3">Escola de gente</h3>
            <label className="label" htmlFor="passwd">
              {t("host-choose-event-label")}
            </label>
            <div className="input-group">
              <input
                type="password"
                id="passwd"
                placeholder={t("host-choose-event-code-placeholder")}
                onChange={(e) => {
                  setTokenEvent13(e.target.value);
                }}
              />
              <SystemButton>{t("host-choose-event-btn-confirm")}</SystemButton>
            </div>
        </form> */}

        {/* <form className="choose-event" onSubmit={handleChooseEvent10}>

<h3 className="h3">OPUS - Homem com H</h3>
<label className="label" htmlFor="passwd">
  {t("host-choose-event-label")}
</label>
<div className="input-group">
  <input
    type="password"
    id="passwd"
    placeholder={t("host-choose-event-code-placeholder")}
    onChange={(e) => {
      setTokenEvent10(e.target.value);
    }}
  />
  <SystemButton>{t("host-choose-event-btn-confirm")}</SystemButton>
</div>
</form> */}

{/* <form className="choose-event" onSubmit={handleChooseEvent11}>

<h3 className="h3">OPUS - Silvio Santos</h3>
<label className="label" htmlFor="passwd">
  {t("host-choose-event-label")}
</label>
<div className="input-group">
  <input
    type="password"
    id="passwd"
    placeholder={t("host-choose-event-code-placeholder")}
    onChange={(e) => {
      setTokenEvent11(e.target.value);
    }}
  />
  <SystemButton>{t("host-choose-event-btn-confirm")}</SystemButton>
</div>
</form> */}

        {/* <form className="choose-event" onSubmit={handleChooseButanta}>

<h3 className="h3">NETFLIX NA CCXP</h3>
<label className="label" htmlFor="passwd">
  {t("host-choose-event-label")}
</label>
<div className="input-group">
  <input
    type="password"
    id="passwd"
    placeholder={t("host-choose-event-code-placeholder")}
    onChange={(e) => {
      setTokenButanta(e.target.value);
    }}
  />
  <SystemButton>{t("host-choose-event-btn-confirm")}</SystemButton>
</div>
</form> */}

{/* <form className="choose-event" onSubmit={handleChooseEvent12}>

<h3 className="h3">OPUS - O Vision Voador</h3>
<label className="label" htmlFor="passwd">
  {t("host-choose-event-label")}
</label>
<div className="input-group">
  <input
    type="password"
    id="passwd"
    placeholder={t("host-choose-event-code-placeholder")}
    onChange={(e) => {
      setTokenEvent12(e.target.value);
    }}
  />
  <SystemButton>{t("host-choose-event-btn-confirm")}</SystemButton>
</div>
</form> */}

            <form className="choose-event" onSubmit={handleChooseEvent14}>

            <h3 className="h3">Sesc Guarulhos</h3>
            <label className="label" htmlFor="passwd">
              {t("host-choose-event-label")}
            </label>
            <div className="input-group">
              <input
                type="password"
                id="passwd"
                placeholder={t("host-choose-event-code-placeholder")}
                onChange={(e) => {
                  setTokenEvent14(e.target.value);
                }}
              />
              <SystemButton>{t("host-choose-event-btn-confirm")}</SystemButton>
            </div>
            </form>

        <form className="choose-event" onSubmit={handleChooseEvent}>
          {/* <h3 className="h3">{t("host-choose-event-name")}</h3> */}
          <h3 className="h3">Petra Von Kant</h3>
          <label className="label" htmlFor="passwd">
            {t("host-choose-event-label")}
          </label>
          <div className="input-group">
            <input
              type="password"
              id="passwd"
              placeholder={t("host-choose-event-code-placeholder")}
              onChange={(e) => {
                setToken(e.target.value);
              }}
            />
            <SystemButton>{t("host-choose-event-btn-confirm")}</SystemButton>
          </div>

        </form>

        {/* <form className="choose-event" onSubmit={handleChooseCampoLimpo}>
          <h3 className="h3">Claro Rio</h3>
          <label className="label" htmlFor="passwd">
            {t("host-choose-event-label")}
          </label>
          <div className="input-group">
            <input
              type="password"
              id="passwd"
              placeholder={t("host-choose-event-code-placeholder")}
              onChange={(e) => {
                setTokenCampoLimpo(e.target.value);
              }}
            />
            <SystemButton>{t("host-choose-event-btn-confirm")}</SystemButton>
          </div>

        </form> */}

        {/* <form className="choose-event" onSubmit={handleChooseEvent18}>
          <h3 className="h3">Claro São Paulo</h3>
          <label className="label" htmlFor="passwd">
            {t("host-choose-event-label")}
          </label>
          <div className="input-group">
            <input
              type="password"
              id="passwd"
              placeholder={t("host-choose-event-code-placeholder")}
              onChange={(e) => {
                setTokenEvent18(e.target.value);
              }}
            />
            <SystemButton>{t("host-choose-event-btn-confirm")}</SystemButton>
          </div>

        </form> */}

        {/* evento simultaneo */}
        {/* <form className="choose-event" onSubmit={handleChoosePinheiros}>

  <h3 className="h3">Opus (Musical - O Novo A Bela e a Fera)</h3>
  <label className="label" htmlFor="passwd">
    {t("host-choose-event-label")}
  </label>
  <div className="input-group">
    <input
      type="password"
      id="passwd"
      placeholder={t("host-choose-event-code-placeholder")}
      onChange={(e) => {
        setTokenPinheiros(e.target.value);
      }}
    />
    <SystemButton>{t("host-choose-event-btn-confirm")}</SystemButton>
  </div>
</form> */}

<form className="choose-event" onSubmit={handleChooseParelheiros}>

<h3 className="h3">Teatro Claro - Matilda</h3>
<label className="label" htmlFor="passwd">
  {t("host-choose-event-label")}
</label>
<div className="input-group">
  <input
    type="password"
    id="passwd"
    placeholder={t("host-choose-event-code-placeholder")}
    onChange={(e) => {
      setTokenParelheiros(e.target.value);
    }}
  />
  <SystemButton>{t("host-choose-event-btn-confirm")}</SystemButton>
</div>
</form>



        {/* Fim */}





        {/* Evento sesc santo amaro */}
        {/* <form className="choose-event" onSubmit={handleChooseGupy}>
          <h3 className="h3">{t("host-choose-event-name-gupy")}</h3>
          <label className="label" htmlFor="passwd">
            {t("host-choose-event-label")}
          </label>
          <div className="input-group">
            <input
              type="password"
              id="passwd"
              placeholder={t("host-choose-event-code-placeholder")}
              onChange={(e) => {
                setTokenGupy(e.target.value);
              }}
            />
            <SystemButton>{t("host-choose-event-btn-confirm")}</SystemButton>
          </div>
        </form> */}
        {/* Fim do evento da Gupy */}

        {/* <form className="choose-event" onSubmit={handleChooseYoutube}>
          <h3 className="h3">Fundação Bachianas</h3>
          <label className="label" htmlFor="passwd">
            {t("host-choose-event-label")}
          </label>
          <div className="input-group">
            <input
              type="password"
              id="passwd"
              placeholder={t("host-choose-event-code-placeholder")}
              onChange={(e) => {
                setTokenYoutube(e.target.value);
              }}
            />
            <SystemButton>{t("host-choose-event-btn-confirm")}</SystemButton>
          </div>
        </form> */}

        {/* <form className="choose-event" onSubmit={handleChooseMBoiMirim}>
          <h3 className="h3">Musical "80 A DÉCADA DO VALE TUDO"</h3>
          <label className="label" htmlFor="passwd">
            {t("host-choose-event-label")}
          </label>
          <div className="input-group">
            <input
              type="password"
              id="passwd"
              placeholder={t("host-choose-event-code-placeholder")}
              onChange={(e) => {
                setTokenMBoiMirim(e.target.value);
              }}
            />
            <SystemButton>{t("host-choose-event-btn-confirm")}</SystemButton>
          </div>
        </form> */}



<form className="choose-event" onSubmit={handleChooseEventDemo}>
          <h3 className="h3">{t("host-choose-event-name-demo")}</h3>
          <label className="label" htmlFor="passwd">
            {t("host-choose-event-label")}
          </label>
          <div className="input-group">
            <input
              type="password"
              id="passwd"
              placeholder={t("host-choose-event-code-placeholder")}
              onChange={(e) => {
                setTokenDemo(e.target.value);
              }}
            />
            <SystemButton>{t("host-choose-event-btn-confirm")}</SystemButton>
          </div>
          {/* {alertDemo && (
            <div className="alert">
              <small>
                <img src={iconAlert} alt="ícone de alerta" />
                {t("host-login-btn-error")}
              </small>
            </div>
          )} */}
        </form>

        <form className="choose-event" onSubmit={handleChooseCapelaSocorro}>
  {/* <h3 className="h3">{t("host-choose-event-name")}</h3> */}
  <h3 className="h3">Ambiente Preview</h3>
  <label className="label" htmlFor="passwd">
    {t("host-choose-event-label")}
  </label>
  <div className="input-group">
    <input
      type="password"
      id="passwd"
      placeholder={t("host-choose-event-code-placeholder")}
      onChange={(e) => {
        setTokenCapelaSocorro(e.target.value);
      }}
    />
    <SystemButton>{t("host-choose-event-btn-confirm")}</SystemButton>
  </div>
</form>

        
        <div className="back">
          <Link to="/organizador/login">
            <img src={arrowBack} alt="Voltar" />
            {t("btn-back")}
          </Link>
        </div>
        <Footer />
      </div>
    </Container>
  );
};

export default ChooseEvent;
